export const CsIframe = () => import('../../client/components/CsIframe.vue' /* webpackChunkName: "components/cs-iframe" */).then(c => wrapFunctional(c.default || c))
export const DefaultAlert = () => import('../../client/components/DefaultAlert.vue' /* webpackChunkName: "components/default-alert" */).then(c => wrapFunctional(c.default || c))
export const DefaultConfirm = () => import('../../client/components/DefaultConfirm.vue' /* webpackChunkName: "components/default-confirm" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../client/components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const TransitionPage = () => import('../../client/components/TransitionPage.vue' /* webpackChunkName: "components/transition-page" */).then(c => wrapFunctional(c.default || c))
export const AlgorithmHyperParameterInputForm = () => import('../../client/components/algorithm/HyperParameterInputForm.vue' /* webpackChunkName: "components/algorithm-hyper-parameter-input-form" */).then(c => wrapFunctional(c.default || c))
export const AlgorithmParameterCreateForm = () => import('../../client/components/algorithm/ParameterCreateForm.vue' /* webpackChunkName: "components/algorithm-parameter-create-form" */).then(c => wrapFunctional(c.default || c))
export const AlgorithmParameterInputForm = () => import('../../client/components/algorithm/ParameterInputForm.vue' /* webpackChunkName: "components/algorithm-parameter-input-form" */).then(c => wrapFunctional(c.default || c))
export const CanvasAnnotInference = () => import('../../client/components/canvas/AnnotInference.vue' /* webpackChunkName: "components/canvas-annot-inference" */).then(c => wrapFunctional(c.default || c))
export const CanvasClassificateWorkspace = () => import('../../client/components/canvas/ClassificateWorkspace.vue' /* webpackChunkName: "components/canvas-classificate-workspace" */).then(c => wrapFunctional(c.default || c))
export const CanvasFabricWorkspace = () => import('../../client/components/canvas/FabricWorkspace.vue' /* webpackChunkName: "components/canvas-fabric-workspace" */).then(c => wrapFunctional(c.default || c))
export const AnnotationBasicInfo = () => import('../../client/components/annotation/BasicInfo.vue' /* webpackChunkName: "components/annotation-basic-info" */).then(c => wrapFunctional(c.default || c))
export const AnnotationBoard = () => import('../../client/components/annotation/Board.vue' /* webpackChunkName: "components/annotation-board" */).then(c => wrapFunctional(c.default || c))
export const AnnotationClassification = () => import('../../client/components/annotation/Classification.vue' /* webpackChunkName: "components/annotation-classification" */).then(c => wrapFunctional(c.default || c))
export const AnnotationDataDisplay = () => import('../../client/components/annotation/DataDisplay.vue' /* webpackChunkName: "components/annotation-data-display" */).then(c => wrapFunctional(c.default || c))
export const AnnotationInfoDisplay = () => import('../../client/components/annotation/InfoDisplay.vue' /* webpackChunkName: "components/annotation-info-display" */).then(c => wrapFunctional(c.default || c))
export const AnnotationObjectdetection = () => import('../../client/components/annotation/Objectdetection.vue' /* webpackChunkName: "components/annotation-objectdetection" */).then(c => wrapFunctional(c.default || c))
export const AnnotationSegmentation = () => import('../../client/components/annotation/Segmentation.vue' /* webpackChunkName: "components/annotation-segmentation" */).then(c => wrapFunctional(c.default || c))
export const AnnotationTargetRegionCf = () => import('../../client/components/annotation/TargetRegionCf.vue' /* webpackChunkName: "components/annotation-target-region-cf" */).then(c => wrapFunctional(c.default || c))
export const AnnotationTargetRegionOd = () => import('../../client/components/annotation/TargetRegionOd.vue' /* webpackChunkName: "components/annotation-target-region-od" */).then(c => wrapFunctional(c.default || c))
export const AnnotationToolbar = () => import('../../client/components/annotation/Toolbar.vue' /* webpackChunkName: "components/annotation-toolbar" */).then(c => wrapFunctional(c.default || c))
export const AnnotationWorkspace = () => import('../../client/components/annotation/Workspace.vue' /* webpackChunkName: "components/annotation-workspace" */).then(c => wrapFunctional(c.default || c))
export const ChartBarChart = () => import('../../client/components/chart/BarChart.vue' /* webpackChunkName: "components/chart-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartBellChart = () => import('../../client/components/chart/BellChart.vue' /* webpackChunkName: "components/chart-bell-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartLineChart = () => import('../../client/components/chart/LineChart.vue' /* webpackChunkName: "components/chart-line-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartMixedChart = () => import('../../client/components/chart/MixedChart.vue' /* webpackChunkName: "components/chart-mixed-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartPieChart = () => import('../../client/components/chart/PieChart.vue' /* webpackChunkName: "components/chart-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartScatterChart = () => import('../../client/components/chart/ScatterChart.vue' /* webpackChunkName: "components/chart-scatter-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartStackedChart = () => import('../../client/components/chart/StackedChart.vue' /* webpackChunkName: "components/chart-stacked-chart" */).then(c => wrapFunctional(c.default || c))
export const CommonMultiSelect = () => import('../../client/components/common/MultiSelect.vue' /* webpackChunkName: "components/common-multi-select" */).then(c => wrapFunctional(c.default || c))
export const CommonSearchSelect = () => import('../../client/components/common/SearchSelect.vue' /* webpackChunkName: "components/common-search-select" */).then(c => wrapFunctional(c.default || c))
export const IconBrush = () => import('../../client/components/icon/Brush.vue' /* webpackChunkName: "components/icon-brush" */).then(c => wrapFunctional(c.default || c))
export const IconCircle = () => import('../../client/components/icon/Circle.vue' /* webpackChunkName: "components/icon-circle" */).then(c => wrapFunctional(c.default || c))
export const IconCursor = () => import('../../client/components/icon/Cursor.vue' /* webpackChunkName: "components/icon-cursor" */).then(c => wrapFunctional(c.default || c))
export const IconDownload = () => import('../../client/components/icon/Download.vue' /* webpackChunkName: "components/icon-download" */).then(c => wrapFunctional(c.default || c))
export const IconEllipse = () => import('../../client/components/icon/Ellipse.vue' /* webpackChunkName: "components/icon-ellipse" */).then(c => wrapFunctional(c.default || c))
export const IconEraser = () => import('../../client/components/icon/Eraser.vue' /* webpackChunkName: "components/icon-eraser" */).then(c => wrapFunctional(c.default || c))
export const IconFilter = () => import('../../client/components/icon/Filter.vue' /* webpackChunkName: "components/icon-filter" */).then(c => wrapFunctional(c.default || c))
export const IconInfo = () => import('../../client/components/icon/Info.vue' /* webpackChunkName: "components/icon-info" */).then(c => wrapFunctional(c.default || c))
export const IconKey = () => import('../../client/components/icon/Key.vue' /* webpackChunkName: "components/icon-key" */).then(c => wrapFunctional(c.default || c))
export const IconMagicWand = () => import('../../client/components/icon/MagicWand.vue' /* webpackChunkName: "components/icon-magic-wand" */).then(c => wrapFunctional(c.default || c))
export const IconNumber = () => import('../../client/components/icon/Number.vue' /* webpackChunkName: "components/icon-number" */).then(c => wrapFunctional(c.default || c))
export const IconPentagon = () => import('../../client/components/icon/Pentagon.vue' /* webpackChunkName: "components/icon-pentagon" */).then(c => wrapFunctional(c.default || c))
export const IconPolygon = () => import('../../client/components/icon/Polygon.vue' /* webpackChunkName: "components/icon-polygon" */).then(c => wrapFunctional(c.default || c))
export const IconSam = () => import('../../client/components/icon/Sam.vue' /* webpackChunkName: "components/icon-sam" */).then(c => wrapFunctional(c.default || c))
export const IconSquare = () => import('../../client/components/icon/Square.vue' /* webpackChunkName: "components/icon-square" */).then(c => wrapFunctional(c.default || c))
export const IconUpload = () => import('../../client/components/icon/Upload.vue' /* webpackChunkName: "components/icon-upload" */).then(c => wrapFunctional(c.default || c))
export const IconZoomIn = () => import('../../client/components/icon/ZoomIn.vue' /* webpackChunkName: "components/icon-zoom-in" */).then(c => wrapFunctional(c.default || c))
export const IconZoomOut = () => import('../../client/components/icon/ZoomOut.vue' /* webpackChunkName: "components/icon-zoom-out" */).then(c => wrapFunctional(c.default || c))
export const InputDateRangePicker = () => import('../../client/components/input/DateRangePicker.vue' /* webpackChunkName: "components/input-date-range-picker" */).then(c => wrapFunctional(c.default || c))
export const InputPassword = () => import('../../client/components/input/Password.vue' /* webpackChunkName: "components/input-password" */).then(c => wrapFunctional(c.default || c))
export const LayoutAdminSideNavigationBar = () => import('../../client/components/layout/AdminSideNavigationBar.vue' /* webpackChunkName: "components/layout-admin-side-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutGlobalNavigationBar = () => import('../../client/components/layout/GlobalNavigationBar.vue' /* webpackChunkName: "components/layout-global-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutSideNavigationBar = () => import('../../client/components/layout/SideNavigationBar.vue' /* webpackChunkName: "components/layout-side-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutCopyright = () => import('../../client/components/layout/copyright.vue' /* webpackChunkName: "components/layout-copyright" */).then(c => wrapFunctional(c.default || c))
export const MarkupFilterSection = () => import('../../client/components/markup/FilterSection.vue' /* webpackChunkName: "components/markup-filter-section" */).then(c => wrapFunctional(c.default || c))
export const MarkupImageView = () => import('../../client/components/markup/ImageView.vue' /* webpackChunkName: "components/markup-image-view" */).then(c => wrapFunctional(c.default || c))
export const MarkupLoadLabeling = () => import('../../client/components/markup/LoadLabeling.vue' /* webpackChunkName: "components/markup-load-labeling" */).then(c => wrapFunctional(c.default || c))
export const MarkupLoading = () => import('../../client/components/markup/Loading.vue' /* webpackChunkName: "components/markup-loading" */).then(c => wrapFunctional(c.default || c))
export const MarkupPageTitle = () => import('../../client/components/markup/PageTitle.vue' /* webpackChunkName: "components/markup-page-title" */).then(c => wrapFunctional(c.default || c))
export const MarkupPaginationBak = () => import('../../client/components/markup/Pagination.bak.vue' /* webpackChunkName: "components/markup-pagination-bak" */).then(c => wrapFunctional(c.default || c))
export const MarkupPagination = () => import('../../client/components/markup/Pagination.vue' /* webpackChunkName: "components/markup-pagination" */).then(c => wrapFunctional(c.default || c))
export const MarkupPaginationClean = () => import('../../client/components/markup/PaginationClean.vue' /* webpackChunkName: "components/markup-pagination-clean" */).then(c => wrapFunctional(c.default || c))
export const MarkupPredictionView = () => import('../../client/components/markup/PredictionView.vue' /* webpackChunkName: "components/markup-prediction-view" */).then(c => wrapFunctional(c.default || c))
export const MarkupResultNone = () => import('../../client/components/markup/ResultNone.vue' /* webpackChunkName: "components/markup-result-none" */).then(c => wrapFunctional(c.default || c))
export const MarkupSimpleCompanyView = () => import('../../client/components/markup/SimpleCompanyView.vue' /* webpackChunkName: "components/markup-simple-company-view" */).then(c => wrapFunctional(c.default || c))
export const MarkupSimpleDatasView = () => import('../../client/components/markup/SimpleDatasView.vue' /* webpackChunkName: "components/markup-simple-datas-view" */).then(c => wrapFunctional(c.default || c))
export const MarkupSwitch = () => import('../../client/components/markup/Switch.vue' /* webpackChunkName: "components/markup-switch" */).then(c => wrapFunctional(c.default || c))
export const ModalChangeCompanyName = () => import('../../client/components/modal/ChangeCompanyName.vue' /* webpackChunkName: "components/modal-change-company-name" */).then(c => wrapFunctional(c.default || c))
export const ModalChangeUserName = () => import('../../client/components/modal/ChangeUserName.vue' /* webpackChunkName: "components/modal-change-user-name" */).then(c => wrapFunctional(c.default || c))
export const ModalClassEdit = () => import('../../client/components/modal/ClassEdit.vue' /* webpackChunkName: "components/modal-class-edit" */).then(c => wrapFunctional(c.default || c))
export const ModalCompanySelect = () => import('../../client/components/modal/CompanySelect.vue' /* webpackChunkName: "components/modal-company-select" */).then(c => wrapFunctional(c.default || c))
export const ModalCompareImage = () => import('../../client/components/modal/CompareImage.vue' /* webpackChunkName: "components/modal-compare-image" */).then(c => wrapFunctional(c.default || c))
export const ModalConfusionMatrixImage = () => import('../../client/components/modal/ConfusionMatrixImage.vue' /* webpackChunkName: "components/modal-confusion-matrix-image" */).then(c => wrapFunctional(c.default || c))
export const ModalConfusionMatrixResult = () => import('../../client/components/modal/ConfusionMatrixResult.vue' /* webpackChunkName: "components/modal-confusion-matrix-result" */).then(c => wrapFunctional(c.default || c))
export const ModalCopyNotice = () => import('../../client/components/modal/CopyNotice.vue' /* webpackChunkName: "components/modal-copy-notice" */).then(c => wrapFunctional(c.default || c))
export const ModalDatasetFolderRename = () => import('../../client/components/modal/DatasetFolderRename.vue' /* webpackChunkName: "components/modal-dataset-folder-rename" */).then(c => wrapFunctional(c.default || c))
export const ModalDatasetSelect = () => import('../../client/components/modal/DatasetSelect.vue' /* webpackChunkName: "components/modal-dataset-select" */).then(c => wrapFunctional(c.default || c))
export const ModalDatasetUpload = () => import('../../client/components/modal/DatasetUpload.vue' /* webpackChunkName: "components/modal-dataset-upload" */).then(c => wrapFunctional(c.default || c))
export const ModalDefaultProjectSetting = () => import('../../client/components/modal/DefaultProjectSetting.vue' /* webpackChunkName: "components/modal-default-project-setting" */).then(c => wrapFunctional(c.default || c))
export const ModalDescription = () => import('../../client/components/modal/Description.vue' /* webpackChunkName: "components/modal-description" */).then(c => wrapFunctional(c.default || c))
export const ModalFabricCanvasZoom = () => import('../../client/components/modal/FabricCanvasZoom.vue' /* webpackChunkName: "components/modal-fabric-canvas-zoom" */).then(c => wrapFunctional(c.default || c))
export const ModalImageZoom = () => import('../../client/components/modal/ImageZoom.vue' /* webpackChunkName: "components/modal-image-zoom" */).then(c => wrapFunctional(c.default || c))
export const ModalLearningCompareAdd = () => import('../../client/components/modal/LearningCompareAdd.vue' /* webpackChunkName: "components/modal-learning-compare-add" */).then(c => wrapFunctional(c.default || c))
export const ModalLearningSelect = () => import('../../client/components/modal/LearningSelect.vue' /* webpackChunkName: "components/modal-learning-select" */).then(c => wrapFunctional(c.default || c))
export const ModalLinePeriodReport = () => import('../../client/components/modal/LinePeriodReport.vue' /* webpackChunkName: "components/modal-line-period-report" */).then(c => wrapFunctional(c.default || c))
export const ModalLineTypeReport = () => import('../../client/components/modal/LineTypeReport.vue' /* webpackChunkName: "components/modal-line-type-report" */).then(c => wrapFunctional(c.default || c))
export const ModalManagerAdd = () => import('../../client/components/modal/ManagerAdd.vue' /* webpackChunkName: "components/modal-manager-add" */).then(c => wrapFunctional(c.default || c))
export const ModalManualDataset = () => import('../../client/components/modal/ManualDataset.vue' /* webpackChunkName: "components/modal-manual-dataset" */).then(c => wrapFunctional(c.default || c))
export const ModalModelHistoryDetail = () => import('../../client/components/modal/ModelHistoryDetail.vue' /* webpackChunkName: "components/modal-model-history-detail" */).then(c => wrapFunctional(c.default || c))
export const ModalMonitoringResult = () => import('../../client/components/modal/MonitoringResult.vue' /* webpackChunkName: "components/modal-monitoring-result" */).then(c => wrapFunctional(c.default || c))
export const ModalMultipleSelectDatas = () => import('../../client/components/modal/MultipleSelectDatas.vue' /* webpackChunkName: "components/modal-multiple-select-datas" */).then(c => wrapFunctional(c.default || c))
export const ModalNewDatasetUpload = () => import('../../client/components/modal/NewDatasetUpload.vue' /* webpackChunkName: "components/modal-new-dataset-upload" */).then(c => wrapFunctional(c.default || c))
export const ModalPasswordAlarm = () => import('../../client/components/modal/PasswordAlarm.vue' /* webpackChunkName: "components/modal-password-alarm" */).then(c => wrapFunctional(c.default || c))
export const ModalPasswordReset = () => import('../../client/components/modal/PasswordReset.vue' /* webpackChunkName: "components/modal-password-reset" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingDatas = () => import('../../client/components/modal/ProcessingDatas.vue' /* webpackChunkName: "components/modal-processing-datas" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingSampleImages = () => import('../../client/components/modal/ProcessingSampleImages.vue' /* webpackChunkName: "components/modal-processing-sample-images" */).then(c => wrapFunctional(c.default || c))
export const ModalProjectSelect = () => import('../../client/components/modal/ProjectSelect.vue' /* webpackChunkName: "components/modal-project-select" */).then(c => wrapFunctional(c.default || c))
export const ModalRelearningSelect = () => import('../../client/components/modal/RelearningSelect.vue' /* webpackChunkName: "components/modal-relearning-select" */).then(c => wrapFunctional(c.default || c))
export const ModalSettingDetail = () => import('../../client/components/modal/SettingDetail.vue' /* webpackChunkName: "components/modal-setting-detail" */).then(c => wrapFunctional(c.default || c))
export const ModalShowErrorMessage = () => import('../../client/components/modal/ShowErrorMessage.vue' /* webpackChunkName: "components/modal-show-error-message" */).then(c => wrapFunctional(c.default || c))
export const ModalUserAdd = () => import('../../client/components/modal/UserAdd.vue' /* webpackChunkName: "components/modal-user-add" */).then(c => wrapFunctional(c.default || c))
export const ModalUserPermissionEdit = () => import('../../client/components/modal/UserPermissionEdit.vue' /* webpackChunkName: "components/modal-user-permission-edit" */).then(c => wrapFunctional(c.default || c))
export const TrainClassInferenceInfo = () => import('../../client/components/train/ClassInferenceInfo.vue' /* webpackChunkName: "components/train-class-inference-info" */).then(c => wrapFunctional(c.default || c))
export const TrainClassInfo = () => import('../../client/components/train/ClassInfo.vue' /* webpackChunkName: "components/train-class-info" */).then(c => wrapFunctional(c.default || c))
export const TrainClassLearningInfo = () => import('../../client/components/train/ClassLearningInfo.vue' /* webpackChunkName: "components/train-class-learning-info" */).then(c => wrapFunctional(c.default || c))
export const TrainDatasetClassInputBak = () => import('../../client/components/train/DatasetClassInput.bak.vue' /* webpackChunkName: "components/train-dataset-class-input-bak" */).then(c => wrapFunctional(c.default || c))
export const TrainDatasetClassInput = () => import('../../client/components/train/DatasetClassInput.vue' /* webpackChunkName: "components/train-dataset-class-input" */).then(c => wrapFunctional(c.default || c))
export const TrainDatasetFolder = () => import('../../client/components/train/DatasetFolder.vue' /* webpackChunkName: "components/train-dataset-folder" */).then(c => wrapFunctional(c.default || c))
export const TrainExistingDatasetInput = () => import('../../client/components/train/ExistingDatasetInput.vue' /* webpackChunkName: "components/train-existing-dataset-input" */).then(c => wrapFunctional(c.default || c))
export const TrainExistingDatasetList = () => import('../../client/components/train/ExistingDatasetList.vue' /* webpackChunkName: "components/train-existing-dataset-list" */).then(c => wrapFunctional(c.default || c))
export const TrainNewDatasetInput = () => import('../../client/components/train/NewDatasetInput.vue' /* webpackChunkName: "components/train-new-dataset-input" */).then(c => wrapFunctional(c.default || c))
export const TrainNewDatasetList = () => import('../../client/components/train/NewDatasetList.vue' /* webpackChunkName: "components/train-new-dataset-list" */).then(c => wrapFunctional(c.default || c))
export const TrainPerformanceImprovementNotice = () => import('../../client/components/train/PerformanceImprovementNotice.vue' /* webpackChunkName: "components/train-performance-improvement-notice" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceBoard = () => import('../../client/components/workspace/Board.vue' /* webpackChunkName: "components/workspace-board" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceCfImageCard = () => import('../../client/components/workspace/CfImageCard.vue' /* webpackChunkName: "components/workspace-cf-image-card" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceDataDisplay = () => import('../../client/components/workspace/DataDisplay.vue' /* webpackChunkName: "components/workspace-data-display" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceDirectory = () => import('../../client/components/workspace/Directory.vue' /* webpackChunkName: "components/workspace-directory" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceFolderDisplay = () => import('../../client/components/workspace/FolderDisplay.vue' /* webpackChunkName: "components/workspace-folder-display" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceMonitoringItem = () => import('../../client/components/workspace/MonitoringItem.vue' /* webpackChunkName: "components/workspace-monitoring-item" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceTitle = () => import('../../client/components/workspace/Title.vue' /* webpackChunkName: "components/workspace-title" */).then(c => wrapFunctional(c.default || c))
export const WorkspaceToolHeadline = () => import('../../client/components/workspace/ToolHeadline.vue' /* webpackChunkName: "components/workspace-tool-headline" */).then(c => wrapFunctional(c.default || c))
export const AnnotationCfGuide = () => import('../../client/components/annotation/cf/Guide.vue' /* webpackChunkName: "components/annotation-cf-guide" */).then(c => wrapFunctional(c.default || c))
export const AnnotationCfImageCard = () => import('../../client/components/annotation/cf/ImageCard.vue' /* webpackChunkName: "components/annotation-cf-image-card" */).then(c => wrapFunctional(c.default || c))
export const AnnotationOdAiResultThumbList = () => import('../../client/components/annotation/od/AiResultThumbList.vue' /* webpackChunkName: "components/annotation-od-ai-result-thumb-list" */).then(c => wrapFunctional(c.default || c))
export const AnnotationOdCurrentInfoDisplay = () => import('../../client/components/annotation/od/CurrentInfoDisplay.vue' /* webpackChunkName: "components/annotation-od-current-info-display" */).then(c => wrapFunctional(c.default || c))
export const AnnotationOdResultClassInfoTooltip = () => import('../../client/components/annotation/od/ResultClassInfoTooltip.vue' /* webpackChunkName: "components/annotation-od-result-class-info-tooltip" */).then(c => wrapFunctional(c.default || c))
export const IconGuideAll = () => import('../../client/components/icon/guide/All.vue' /* webpackChunkName: "components/icon-guide-all" */).then(c => wrapFunctional(c.default || c))
export const IconGuideDelete = () => import('../../client/components/icon/guide/Delete.vue' /* webpackChunkName: "components/icon-guide-delete" */).then(c => wrapFunctional(c.default || c))
export const IconGuideLabeling = () => import('../../client/components/icon/guide/Labeling.vue' /* webpackChunkName: "components/icon-guide-labeling" */).then(c => wrapFunctional(c.default || c))
export const IconGuideMultiple = () => import('../../client/components/icon/guide/Multiple.vue' /* webpackChunkName: "components/icon-guide-multiple" */).then(c => wrapFunctional(c.default || c))
export const IconGuideScope = () => import('../../client/components/icon/guide/Scope.vue' /* webpackChunkName: "components/icon-guide-scope" */).then(c => wrapFunctional(c.default || c))
export const IconGuideUndo = () => import('../../client/components/icon/guide/Undo.vue' /* webpackChunkName: "components/icon-guide-undo" */).then(c => wrapFunctional(c.default || c))
export const IconGuideMacAll = () => import('../../client/components/icon/guideMac/All.vue' /* webpackChunkName: "components/icon-guide-mac-all" */).then(c => wrapFunctional(c.default || c))
export const IconGuideMacDelete = () => import('../../client/components/icon/guideMac/Delete.vue' /* webpackChunkName: "components/icon-guide-mac-delete" */).then(c => wrapFunctional(c.default || c))
export const IconGuideMacLabeling = () => import('../../client/components/icon/guideMac/Labeling.vue' /* webpackChunkName: "components/icon-guide-mac-labeling" */).then(c => wrapFunctional(c.default || c))
export const IconGuideMacMultiple = () => import('../../client/components/icon/guideMac/Multiple.vue' /* webpackChunkName: "components/icon-guide-mac-multiple" */).then(c => wrapFunctional(c.default || c))
export const IconGuideMacScope = () => import('../../client/components/icon/guideMac/Scope.vue' /* webpackChunkName: "components/icon-guide-mac-scope" */).then(c => wrapFunctional(c.default || c))
export const IconGuideMacUndo = () => import('../../client/components/icon/guideMac/Undo.vue' /* webpackChunkName: "components/icon-guide-mac-undo" */).then(c => wrapFunctional(c.default || c))
export const ModalDatasetDownloadRequestList = () => import('../../client/components/modal/dataset/DownloadRequestList.vue' /* webpackChunkName: "components/modal-dataset-download-request-list" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingAiLabeling = () => import('../../client/components/modal/processing/AiLabeling.vue' /* webpackChunkName: "components/modal-processing-ai-labeling" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingCfResult = () => import('../../client/components/modal/processing/CfResult.vue' /* webpackChunkName: "components/modal-processing-cf-result" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingClassMerge = () => import('../../client/components/modal/processing/ClassMerge.vue' /* webpackChunkName: "components/modal-processing-class-merge" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingClassRemove = () => import('../../client/components/modal/processing/ClassRemove.vue' /* webpackChunkName: "components/modal-processing-class-remove" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingControl = () => import('../../client/components/modal/processing/Control.vue' /* webpackChunkName: "components/modal-processing-control" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingExternalLabeling = () => import('../../client/components/modal/processing/ExternalLabeling.vue' /* webpackChunkName: "components/modal-processing-external-labeling" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingMerge = () => import('../../client/components/modal/processing/Merge.vue' /* webpackChunkName: "components/modal-processing-merge" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingOdResult = () => import('../../client/components/modal/processing/OdResult.vue' /* webpackChunkName: "components/modal-processing-od-result" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingCopy = () => import('../../client/components/modal/processing/copy.vue' /* webpackChunkName: "components/modal-processing-copy" */).then(c => wrapFunctional(c.default || c))
export const TrainProcessingCfLabeling = () => import('../../client/components/train/processing/CfLabeling.vue' /* webpackChunkName: "components/train-processing-cf-labeling" */).then(c => wrapFunctional(c.default || c))
export const TrainProcessingOdResultBellChart = () => import('../../client/components/train/processing/OdResultBellChart.vue' /* webpackChunkName: "components/train-processing-od-result-bell-chart" */).then(c => wrapFunctional(c.default || c))
export const AnnotationCfAiLabelingResultSampleCard = () => import('../../client/components/annotation/cf/AiLabeling/ResultSampleCard.vue' /* webpackChunkName: "components/annotation-cf-ai-labeling-result-sample-card" */).then(c => wrapFunctional(c.default || c))
export const AnnotationCfAiLabelingUntrainedResult = () => import('../../client/components/annotation/cf/AiLabeling/UntrainedResult.vue' /* webpackChunkName: "components/annotation-cf-ai-labeling-untrained-result" */).then(c => wrapFunctional(c.default || c))
export const AnnotationOdAiLabelingUntrainedResult = () => import('../../client/components/annotation/od/AiLabeling/UntrainedResult.vue' /* webpackChunkName: "components/annotation-od-ai-labeling-untrained-result" */).then(c => wrapFunctional(c.default || c))
export const ModalProcessingAugmentationSettingDetail = () => import('../../client/components/modal/processing/augmentation/SettingDetail.vue' /* webpackChunkName: "components/modal-processing-augmentation-setting-detail" */).then(c => wrapFunctional(c.default || c))
export const TrainProcessingAugmentationInputParams = () => import('../../client/components/train/processing/augmentation/InputParams.vue' /* webpackChunkName: "components/train-processing-augmentation-input-params" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
