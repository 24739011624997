export default async function (context) {
  const { isServer, store, redirect, app, route, from } = context;
  if (process.client && app.$config.is_ssl && window.location.protocol === 'http:') {
    const splitBaseUrl = window.location.href.split('http://');
    window.location.replace('https://' + splitBaseUrl[1]);
  }

  const token = store.state.token;
  const userInfo = store.state.user;

  const setUserDefaultProject = async (redirection) => {
    const userInfo = store.state.user;
    if (userInfo.default_project) {
      await store.dispatch('setCurrentProject', userInfo.default_project);
      if (userInfo.default_project.lines && userInfo.default_project.lines.length > 0) {
        await store.dispatch('setCurrentLine', userInfo.default_project.lines[0]);
      }
      return redirection;
    } else if (userInfo.projects.length > 0) {
      await store.dispatch('setCurrentProject', userInfo.projects[0]);
      return redirection;
    } else {
      await store.dispatch('logout');
      return '/auth/login';
    }
  };

  // console.log('from:', from)
  // console.log('to:', route)
  if (
    token === null &&
    route.name !== 'auth-login' &&
    route.name !== 'notices' &&
    route.name !== 'notices-id' &&
    route.name !== 'notices-cs-id' &&
    route.name !== 'intro' &&
    route.name !== 'cs' &&
    route.name !== 'privacyPolicy'
  ) {
    //app.store.commit('SET_REDIRECTION', route.fullPath)
    return redirect('/auth/login');
  } else if (token !== null) {
    if (route.name === 'auth-login') {
      return redirect('/');
    } else if (route.name === 'index' && userInfo.role_id !== 1) {
      // 관리자 (role_id = 1) 외에는 index페이지 접근 불가
      return redirect('/train');
    }
  }

  if (route.path.indexOf('/admin') === 0 && userInfo && userInfo.role_id !== 1) {
    return redirect('/');
  } else if (userInfo && userInfo.role_id === 3) {
    //고객사 관리자 권한
    if (route.path.indexOf('/monitoring') === 0) {
      //현재 프로젝트에 대한 모니터링 권한 확인
      const projects = userInfo.projects;
      if (projects && projects.length > 0) {
        const current_project_id = store.state.current_project ? store.state.current_project.id : projects[0].id;
        const current_project = projects.find((prj) => {
          return prj.id === current_project_id;
        });

        if (current_project.lines && current_project.lines.length < 1) {
          return setUserDefaultProject('/monitoring');
        }
      }
    }
  } else if (userInfo && userInfo.role_id >= 4) {
    //이용자 권한
    const projects = userInfo.projects;
    if (projects && projects.length > 0) {
      const current_project_id = store.state.current_project ? store.state.current_project.id : projects[0].id;
      const current_project = projects.find((prj) => {
        return prj.id === current_project_id;
      });
      if (route.path.indexOf('/train') === 0) {
        //현재 프로젝트에 대한 학습 권한 확인
        if (current_project.get_permission_user && current_project.get_permission_user.length > 0) {
          if (
            current_project.get_permission_user.filter((permission) => {
              return permission.permission_id === 1;
            }).length < 1
          ) {
            // 현재 프로젝트에 대해 학습 권한 없음
            return setUserDefaultProject('/train');
          }
        } else {
          // 현재 프로젝트에 대해 학습, 모니터링 권한 없음
          return setUserDefaultProject('/train');
        }
      } else if (route.path.indexOf('/monitoring') === 0) {
        //현재 프로젝트에 대한 모니터링 권한 확인
        if (current_project.get_permission_user && current_project.get_permission_user.length > 0) {
          if (
            current_project.get_permission_user.filter((permission) => {
              return permission.permission_id === 2;
            }).length < 1
          ) {
            return setUserDefaultProject('/monitoring');
          }
        }
        return setUserDefaultProject('/monitoring');
      }
    }
  }

  if (from.name !== route.name) {
    const previousRequests = store.getters.previousRequest;
    if (previousRequests && previousRequests.length) {
      for (const request of previousRequests) {
        request.cancel();
        store.dispatch('removeRequestRequest', request);
      }
    }
  }

  if (token !== null) {
    if (
      route.name !== 'auth-login' &&
      route.name !== 'notices' &&
      route.name !== 'notices-id' &&
      route.name !== 'intro' &&
      route.name !== 'cs' &&
      route.name !== 'privacyPolicy'
    ) {
      //변경된 사용자 정보 재조회
      // await store.dispatch('getUserInfo', {})
      // await store.dispatch('getCommons')
    }

    /**
     * 2023.06.01 보안 취약점 개선. 추가 요청 내용
     * 비밀번호 설정 강제화 (로그인 시 비밀번호를 강제로 변경하도록 )
     * reset_pwd_by_admin 값을 Store에 저장하여 활용
     */
    const userInfo = store.getters.userInfo;
    const reset_pwd_by_admin = userInfo.reset_pwd_by_admin;
    if (route.name !== 'my-change-password' && reset_pwd_by_admin) {
      const message = require(`@/lang/${app.i18n.locale}.js`).default;
      app.router.app.flashMessage.error({
        message: message.login.changePw,
      });
      return redirect('/my/change-password');
    }
  }

  // 아이프레임에 현재 라우트 적용
  // 라우트에 따라 cs-platform faq 선택된 카테고리 달라짐
  app.store.dispatch('setCurrRouteName', route.name);
  app.store.dispatch('setCsPlatformUrl');
  app.store.dispatch('setCsUrl');
}
